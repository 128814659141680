import React from 'react'
import BackgroundImage from 'gatsby-background-image'

// Images
// import outsideSize from 'img/outside_size.svg'
import insideSize from 'img/inside_size.svg'
import year from 'img/year.svg'
// import bedroom from 'img/bedroom.svg'
import houseRent from 'img/snel_house_rent.svg'
import houseBuy from 'img/snel_house_buy.svg'
import thumbnail from 'img/thumbnail.png'

// Components
import { Paragraph } from 'components/StyledComponents'
import CustomLink from 'components/CustomLink'

// Third Party
import styled from 'styled-components'

const SingleHomeWrapper = styled.div`
  min-width: 250px;
`

const StyledImg = styled(BackgroundImage)`
  transition: 0.25s;
  background-color: ${(props) => props.theme.color.secondary};
  width: 100%;
  height: 200px;
`

const Separator = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${(props) => props.theme.color.main};
`

const Label = styled.div`
  top: 11px;
  left: 0;

  ${Paragraph} {
    white-space: nowrap;
  }
`

const SoldLabel = styled.div`
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;

  pointer-events: none;

  :hover {
    ${StyledImg} {
      transform: scale(1.2);
    }
  }
`

const ImgContainer = styled(CustomLink)`
  display: flex;
  overflow: hidden;
  border-radius: 15px;

  :hover {
    ${StyledImg} {
      transform: scale(1.2);
    }
  }
`

const Detail = styled.div``

const Details = styled.div`
  @media (max-width: 360px) {
    flex-wrap: wrap;

    ${Detail} {
      width: 50%;
    }
  }
`

const SingleBog = ({ data: { node: data }, className }) => {
  const formatter = new Intl.NumberFormat('nl-NL', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 0
  })

  function addGradient(imageProp) {
    const gradient = [
      imageProp,
      `linear-gradient(87deg, rgba(0,24,67,0.55) 0%, rgba(0,24,67,0.55) 100%)`,
    ].reverse()
    return gradient
  }

  const checkKoopConditie = () => {
    let returnString = ''

    if (data.recapBog.koopconditie !== 'Onbekend') {
      if (data.recapBog.koopconditie === 'VRIJ_OP_NAAM') {
        returnString = 'v.o.n.'
      } else if (data.recapBog.koopconditie === 'KOSTEN_KOPER') {
        returnString = 'k.k.'
      } else {
        returnString = 'N/A'
      }
    } else {
      returnString = 'N/A'
    }

    return returnString
  }

  const checkHuurConditie = () => {
    let returnString = ''

    if (data.recapBog.huurconditie !== 'Onbekend') {
      if (data.recapBog.huurconditie === 'PER_MAAND') {
        returnString = 'p/m.'
      } else if (data.recapBog.huurconditie === 'PER_JAAR') {
        returnString = 'p/j.'
      } else {
        returnString = 'N/A'
      }
    } else {
      returnString = 'N/A'
    }

    return returnString
  }

  return (
    <SingleHomeWrapper className={`${className ? `${className}` : ``}`}>
      <ImgContainer to={`/aanbod/bedrijfsaanbod/${data.slug}`} className="position-relative">
        <img src={data.recapBog.image ? data.recapBog.image : thumbnail} alt="" width="100%" height="250" />
        <Label className="position-absolute">
          <div className="d-flex px-2 py-1 color-background-contrast">
            {data.recapBog.koop && !data.recapBog.huur && (
              <>
                <img className="pr-2" src={houseBuy} alt="" />
                <Paragraph className="text-uppercase font-size-s color-text-light font-weight-xl">Te koop</Paragraph>
              </>
            )}
            {data.recapBog.huur && !data.recapBog.koop && (
              <>
                <img className="pr-2" src={houseRent} alt="" />
                <Paragraph className="text-uppercase font-size-s color-text-light font-weight-xl">Te huur</Paragraph>
              </>
            )}
            {data.recapBog.huur && data.recapBog.koop && (
              <>
                <img className="pr-2" src={houseBuy} alt="" />
                <Paragraph className="text-uppercase font-size-s color-text-light font-weight-xl">
                  Te koop
                  <strong className="px-2">/</strong>
                </Paragraph>
                <img className="pr-2" src={houseRent} alt="" />
                <Paragraph className="text-uppercase font-size-s color-text-light font-weight-xl">Te huur</Paragraph>
              </>
            )}
          </div>
        </Label>
      </ImgContainer>
      <div className="p-2">
        <Paragraph className="font-weight-xl font-size-sm text-uppercase">{`${data.recapBog.address}, ${data.recapBog.city}`}</Paragraph>
        <Paragraph className="font-size-sm text-uppercase">{`${data.recapBog.koop ? `${formatter.format(data.recapBog.price)},- ${checkKoopConditie()}` : `${formatter.format(data.recapBog.price)},- ${checkHuurConditie()}`}`}</Paragraph>
        <Separator className="my-2" />
        <Details className="d-flex justify-content-evenly">
          {data.recapBog.totaleOppervlakte !== 'Onbekend' && (
            <Detail className="d-flex">
              <img className="pr-1" src={insideSize} alt="" />
              <Paragraph>{`${data.recapBog.totaleOppervlakte} m²`}</Paragraph>
            </Detail>
          )}
          {data.recapBog.woonkamerOppervlakte !== 'Onbekend' && (
            <Detail className="d-flex">
              <img className="pr-1" src={insideSize} alt="" />
              <Paragraph>{`${data.recapBog.woonkamerOppervlakte} m²`}</Paragraph>
            </Detail>
          )}
          {data.recapBog.buildYear !== 'Onbekend' && (
            <Detail className="d-flex">
              <img className="pr-1" src={year} alt="" />
              {data.recapBog.buildYear.length > 4 ? (
                <Paragraph>{data.recapBog.buildYear.replace(/_/g, ' ')}</Paragraph>
              ) : (
                <Paragraph>{data.recapBog.buildYear}</Paragraph>
              )}
            </Detail>
          )}
        </Details>
      </div>
    </SingleHomeWrapper>
  )
}

export default SingleBog
